<template>
  <div>
    <v-data-table
      class="coaching-table-display"
      :class="{'not-default-page-size': !isDefaultNewSize}"
      :items="pItems"
      :loading="pLoading"
      :server-items-length="pTotalCount"
      :headers="headers"
      @update:options="paginate($event)"
      :footer-props="footerProps"
      multi-sort
      :options="{
        page: newPageIndex,
        itemsPerPage: newPageSize,
        sortBy: newSort.sortBy,
        sortDesc: newSort.sortDesc,
      }"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]>
        <span
          :key="header.value"
          :class="{
            'not-default-header-sort': isNotDefaultSortItems.includes(header.value)
          }"
        >
          {{ header.text }}
        </span>
      </template>

      <template v-slot:[`item.targetDriver`]="{ item }">
        <router-link
          class="driver-name"
          :to="`/infractions/driver/${item.targetDriver.id}`"
          ><h4>{{ item.targetDriver.name }}</h4></router-link
        >
      </template>

      <template v-slot:[`item.targetInfractionsCount`]="{ item }">
        {{ item.targetInfractionsCount }}
      </template>

      <template v-slot:[`item.mostFrequentInfraction`]="{ item }">
        <v-chip class="red white--text">
          <v-avatar left class="red darken-4">
            {{ item.mostFrequentInfraction.count }}
          </v-avatar>
          {{ item.mostFrequentInfraction.criteria.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ momentDateTime(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { dateTimeMomentFormat } from "../../utils/utils";
import tableSortParserMixin from "../../utils/table-sort-parser";

const locService = Vue.prototype.$localizationService;

export default {
  name: "NewCoachingTable",

  mixins: [tableSortParserMixin],

  props: {
    pLoading: Boolean,
    pItems: Array,
    pTotalCount: Number,
  },

  data: () => ({
    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      {
        text: locService.localize(
          "coaching_page.auto_coaching.new_coaching.header.target_driver",
        ),
        value: "targetDriver",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.auto_coaching.new_coaching.header.created_at",
        ),
        value: "createdAt",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.auto_coaching.new_coaching.header.infractions_count",
        ),
        value: "targetInfractionsCount",
        sortable: true,
        align: "start",
      },
      {
        text: locService.localize(
          "coaching_page.auto_coaching.new_coaching.header.most_frequent_infraction",
        ),
        value: "mostFrequentInfraction",
        sortable: false,
        align: "start",
      },
    ],
  }),

  computed: {
    ...mapState("newCoachingTableSettingsModule", [
      "newPageIndex",
      "newPageSize",
      "newSort",
      "defaultSettings",
    ]),
    ...mapGetters("newCoachingTableSettingsModule", [
      "isDefaultNewSize",
      "isDefaultNewSort",
    ]),

    isNotDefaultSortItems() {
      return !this.isDefaultNewSort
        ? this.getNotDefaultTableSortItems("newSort")
        : [];
    },
  },

  methods: {
    momentDateTime(date) {
      return date ? moment(date).format(dateTimeMomentFormat) : "";
    },

    paginate(data) {
      const currentPage = {
        index: data.page,
        size: data.itemsPerPage,
        sort: {
          sortBy: [...data.sortBy],
          sortDesc: [...data.sortDesc],
        },
      };
      this.$store.commit("newCoachingTableSettingsModule/changeSettings", currentPage);

      this.$emit("onUpdateTable", currentPage);
    },
  },
};
</script>
