var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"coaching-table-display",class:{'not-default-page-size': !_vm.isDefaultNewSize},attrs:{"items":_vm.pItems,"loading":_vm.pLoading,"server-items-length":_vm.pTotalCount,"headers":_vm.headers,"footer-props":_vm.footerProps,"multi-sort":"","options":{
      page: _vm.newPageIndex,
      itemsPerPage: _vm.newPageSize,
      sortBy: _vm.newSort.sortBy,
      sortDesc: _vm.newSort.sortDesc,
    }},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
          'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
        }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.targetDriver",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{staticClass:"driver-name",attrs:{"to":("/infractions/driver/" + (item.targetDriver.id))}},[_c('h4',[_vm._v(_vm._s(item.targetDriver.name))])])]}},{key:"item.targetInfractionsCount",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.targetInfractionsCount)+" ")]}},{key:"item.mostFrequentInfraction",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"red white--text"},[_c('v-avatar',{staticClass:"red darken-4",attrs:{"left":""}},[_vm._v(" "+_vm._s(item.mostFrequentInfraction.count)+" ")]),_vm._v(" "+_vm._s(item.mostFrequentInfraction.criteria.name)+" ")],1)]}},{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateTime(item.createdAt))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }